import { useEffect, useState } from 'react';
import logo from '../../assets/missqua.png';
import { toast } from 'react-toastify';
import { fetchProfiles, saveProfile } from '../../services/ProfileService';
import { ProfileSelectionDTO } from '../../types/ProfileSelectionDTO';
import { useProfile } from '../../contexts/ProfileContextType';
import Loading from '../utils/Loading';

const ProfileSelection: React.FC = () => {
    const [profiles, setProfiles] = useState<ProfileSelectionDTO[]>([]);
    const { selectedProfileId, setSelectedProfileId, setProfile, profile, pdfFormat, setPdfFormat, profileName, setProfileName } = useProfile();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchProfiles().then((data) => {
            setSelectedProfileId(data[0].id);
            setPdfFormat(data[0].baseline);
            setProfiles(data);
            setProfileName(data[0].saved_name);
        }).catch((error) => {
            console.error('Error fetching profiles:', error);
        });
    }, []);

    useEffect(() => {
        if (profiles.length > 0 && profile != null) {
            setPdfFormat(profiles[parseInt(profile.id)].baseline);
            setProfileName(profiles[parseInt(profile.id)].saved_name);
            const currProfile = profiles.find((curr_profile) => curr_profile.id === profile.id);
            if (!currProfile) return;
            setProfile(currProfile);
        }
    }, [profiles, selectedProfileId]);

    const handleProfileChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProfileId(e.target.value);
        setPdfFormat(profiles.find((profile) => profile.id === e.target.value)?.baseline || 'select-style');
        setProfileName(profiles.find((profile) => profile.id === e.target.value)?.saved_name || '');
    };

    const handleSave = async () => {
        if (pdfFormat === 'select-style') {
            toast.info('Please select a profile style first and save it!');
            const element = document.getElementById('pdf-format-selection');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            return;
        }
        setLoading(true);
        await saveProfile(selectedProfileId, profileName, pdfFormat).then(() => {
            toast.success('Profile saved successfully!');
            setProfileName(profileName);
            const element = document.getElementById('profile-informations');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            fetchProfiles().then((data) => {
                setProfiles(data);
            }).catch((error) => {
                toast.error('Failed to save profile.');
                console.error('Error saving profile:', error);
            });
        }).catch((error) => {
            toast.error('Failed to save profile.');
            console.error('Error saving profile:', error);
        }).finally(() => {
            setLoading(false);
        });
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div
            className="flex max-w-4xl mx-auto my-10 bg-white rounded-lg shadow-lg overflow-hidden border border-teal-500"
        >
            <div className="relative w-48 flex-shrink-0 text-center p-4">
                <img src={logo} alt="Logo" className="mx-auto object-cover rounded-full mt-5" />
                <p className="mt-2 text-xl text-gray-600">Miss QuA</p>
            </div>
            <div className="flex-1 p-6 space-y-4">
                <h2 className="text-xl font-semibold text-gray-900">Step 1: Customize your profile</h2>
                <div>
                    <label htmlFor="profile-selection" className="block text-gray-700 font-medium">Your Profile:</label>
                    <div
                        id="profile-section"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm text-base"
                    >
                        {profiles && profiles.length > 0 ? (
                            <>
                                <div>{profiles[0].name} - {profiles[0].saved_name}</div>
                            </>
                        ) : (
                            <div className="text-red-500">No profile available</div>
                        )}
                    </div>
                </div>
                <div>
                    <label htmlFor="pdf-format-selection" className="block text-gray-700 font-medium">Input file format:</label>
                    <select
                        id="pdf-format-selection"
                        value={pdfFormat}
                        onChange={(e) => setPdfFormat(e.target.value)}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 text-base"
                    >
                        <option value="select-style">Please select a document style</option>
                        <option value="form-style">Form Style</option>
                        <option value="job-ad-style">Job Ad Style</option>
                        <option value="info-style">Info Style</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="profile-name-input" className="block text-gray-700 font-medium">Enter your profile name:</label>
                    <input
                        type="text"
                        id="profile-name-input"
                        value={profileName}
                        onChange={(e) => setProfileName(e.target.value)}
                        placeholder="Enter your profile name"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 text-base"
                    />
                </div>
                <div className="flex justify-end">
                    <button
                        onClick={handleSave}
                        className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                    >
                        Save Profile
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProfileSelection;
