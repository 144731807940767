import React from 'react';

type ComparisonModalProps = {
  firstObject: { [key: string]: any };
  secondObject: { [key: string]: any };
  ojectKeyDisplayLabelMap?: { [key: string]: string };
  header: string;
  subHeader?: string;
  keyLabel?: string;
  firstLabel: string;
  secondLabel: string;
  onKeepFirst: () => void;
  onKeepSecond: () => void;
  onClose: () => void;
};

const ComparisonModal: React.FC<ComparisonModalProps> = ({
  firstObject,
  secondObject,
  ojectKeyDisplayLabelMap = {},
  header,
  subHeader,
  keyLabel = 'Parameter',
  firstLabel,
  secondLabel,
  onKeepFirst,
  onKeepSecond,
  onClose,
}) => {
  const keys = Array.from(new Set([...Object.keys(firstObject), ...Object.keys(secondObject)]));

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      id="modalBackdrop"
    >
      <div className="bg-white p-6 rounded-lg w-[80vw] h-[80vh] flex flex-col" onClick={(e) => e.stopPropagation()}>
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-bold">{header}</h3>
          <button onClick={onClose} className="text-xl font-bold hover:text-gray-600">×</button>
        </div>
        <p className="text-sm text-gray-500">{subHeader}</p>
        <div className="mt-4 flex-1 overflow-y-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="p-3 w-[20vw] border-b border-gray-300 text-left">{keyLabel}</th>
                <th className="p-3 w-[30vw] border-b border-gray-300 text-left">{firstLabel}</th>
                <th className="p-3 w-[30vw] border-b border-gray-300 text-left">{secondLabel}</th>
              </tr>
            </thead>
            <tbody>
              {keys.map((key) => (
                <tr key={key}>
                  <td className={`p-3 border-b border-gray-200 ${firstObject[key] !== secondObject[key] ? 'font-bold' : ''}`}>
                    {ojectKeyDisplayLabelMap[key] || key}
                  </td>
                  <td className={`p-3 border-b border-gray-200 ${firstObject[key] !== secondObject[key] ? 'text-red-500' : ''}`}>
                    {firstObject[key]}
                  </td>
                  <td className={`p-3 border-b border-gray-200 ${firstObject[key] !== secondObject[key] ? 'text-green-500' : ''}`}>
                    {secondObject[key]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <button onClick={onKeepFirst} className="py-2 px-4 bg-teal-500 hover:bg-teal-700 text-white font-bold rounded">
            Keep {firstLabel}
          </button>
          <button onClick={onKeepSecond} className="py-2 px-4 bg-teal-500 hover:bg-teal-700 text-white font-bold rounded">
            Keep {secondLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ComparisonModal;